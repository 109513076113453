// Required dependencies
import 'jquery';
import 'bootstrap';


// Assets
// These assets are extracted to [id].bundle.css using MiniCssExtractPlugin, see more on webpack.config.js
// import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import './theme/style.css';
import './theme/custom.scss';

// UI tweaks
import './ui/naja';
import './ui/confirm';